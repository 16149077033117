require('../fonts/Proxima/fonts.css');
require('readmore-js');
require('imagesloaded/imagesloaded.pkgd.js');
require('masonry-layout/dist/masonry.pkgd.js');

import 'slick-carousel';
import 'simplebar/dist/simplebar.css';

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
// require('jquery.maskedinput/src/jquery.maskedinput.js');
require('jquery-mask-plugin/dist/jquery.mask');
require('./parts/header.js');
require('./parts/lazy.js');

require('./Main/index/catalog');
require('./Main/index/reviews');
require('./Main/index/tests');
require('./Main/index/news');
require('./Main/index/partners');
require('./Main/index/fancybox');
// require('./Main/index/video');
require('./Main/news/view');
require('./Main/news/list');
require('./Contacts/contacts.js');
require('./Catalog/main.js');
require('./Catalog/product.js');
// require('./map/google-maps.js');
require('./map/ymaps.js');
require('./Main/photo/_index.js');
require('./Main/photo/_photo.js');
require('./Main/vacancy');

require('./Main/not_liquid/menu');
require('./Main/not_liquid/product');

require('./Main/landing/magnets');

require('./common/communication_form');


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

window.production = function () {
    $('img[data-src]').each(function () {
        var $this = $(this);
        if (!$this.attr('src').startsWith("https://dimetm.ru")) {
            $this.attr('src', "https://dimetm.ru/" + $this.attr('src'));
        }

    });

    $('.product-card__item').each(function () {
        var $this = $(this);
        var current = $this.css('background-image').slice(4, -1).replace(/"/g, "").replace("http://0.0.0.0:9001/", "https://dimetm.ru/");
        $this.css('background-image', "url('" + current + "')");

    });
}
$(function () {
    $('[data-cookie-agreement-button]').on('click', function () {
        document.cookie = "cookie_agreement=agree; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT";
        $('.cookie-agreement').hide();
    });

    $('[data-cookie-agreement-closer]').on('click', function () {
        $('.cookie-agreement').hide();
    });
});
