import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import Inputmask from "inputmask";
import { object } from "underscore";

//Инпут с телефоном, вызывается через PhoneField
//Нужно так же добавлять к событиям модальных окон
export function phoneFieldRender(destroy = false, color, modal = false) {
    let find = modal ? document.querySelector('.modal__container') : document
    const inputs = find.querySelectorAll('[data-phone]');
    const containers = find.querySelectorAll('[data-phone-container]')
    inputs.forEach((el, key) => {
        if(destroy) {
            new Telephone().pluginDestroy()
        } else {
            new Telephone(containers[key], el).init(color)
        }
    })
}
const dialCode = false;
class Telephone {
    constructor(container, input) {
        this.container = container;
        this.input = input;
        this.iti = intlTelInput(this.findInput(), {
            // //TODO сделать локальным
            // Скрипт проверки номера в соответствии со страной
            // utilsScript: '/static/js/common/utils.js',
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
            // Указывает на элемент в котором будет создан выпадающий список
            dropdownContainer: this.findContainer(),
            // Устанавливает страну по дефолту
            initialCountry: "auto",
            // Какие страны показывать
            // onlyCountries: ["ru", "gb", "de", "es", "pt"],
            // Избранные страны
            preferredCountries: ["gb", "de", "es", "pt", "ru"],
            // Показывать код (+7)
            // если true - нужно менять стили и маску
            separateDialCode: dialCode,
            // Авто-плейсхолдер
            autoPlaceholder: "aggressive",
            // геолокация ? initialCountry: 'auto'
            geoIpLookup: function(success, failure) {
                $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "us";
                    success(countryCode);
                });
            },
        });
    }
    init(color) {
        this.iti.promise.then(() => {
            this.customInputStyle(color, dialCode);
            this.customMask();
            this.customDropdownStyle();
        });
    }
    findCountryCode() {
        return this.iti.getSelectedCountryData().dialCode
    }
    findContainer() {
        if (typeof this.container === 'object') {
            return this.container
        } else {
            return this.findInput().closest(`.${this.container}`)
        }
    }
    findInput() {
        let inp;
        if (typeof this.input === 'object') {
            inp = this.input
        } else {
            inp = document.querySelector(`${this.input}`)
        }
        inp.value = ''
        return inp
    }
    customMask() {
        this.setMask();
        this.findInput().addEventListener("countrychange", () => {
            this.setMask();
        });
    }
    setMask() {
        let el = this.findInput();
        let code = this.findCountryCode()
        // очистка инпута
        el.value = '';
        // Получаем плайсхолдер и заменяем все числа на 9
        let currentMask = el
            .getAttribute("placeholder")
            .replace(/[0-9]/g, "9")
        if(code != 1) {
            currentMask = currentMask.slice(code.length)
        }
        Inputmask({
            mask: '+' + `${code.toString().replaceAll('9', '\\9')}` + currentMask,
            positionCaretOnClick:"lvp",
        }).mask(el);
    }
    removeMask() {
        let el = this.findInput();
        Inputmask().remove(el);
    }
    customInputStyle(color = '#fff', dialCode) {
        let el = this.findInput();
        // Изменение стилей для инпута
        el.style.background = color;
        if(dialCode) {
            el.style.paddingLeft = "75px";
            // Изменение стилей селекта separateDialCode
            this.findContainer().querySelector(".iti--separate-dial-code .iti__selected-flag").style.background = 'unset'
        }
    }
    removeCustomInputStyle(color = '#fff') {
        let el = this.findInput();
        // Изменение стилей для инпута
        el.style.paddingLeft = "";
        el.style.background = color;
    }

    customDropdownStyle() {
        this.findInput().addEventListener("open:countrydropdown", () => {
            const dropdown = this.findContainer().querySelector(".iti--container");

            dropdown.style.top = "55px";
            dropdown.style.position = "absolute";
            dropdown.style.left = "50%";
            dropdown.style.transform = "translate(-50%,0)";
            dropdown.style.width = "100%";
            dropdown.style.height = "300px";
            dropdown.style.zIndex = "1060";

            // Изменение стилей списка (отмена горизонтальной прокрутки)
            const countries = this.findContainer().querySelectorAll(".iti__country");
            countries.forEach((el) => (el.style.whiteSpace = "break-spaces"));

            const counryList = this.findContainer().querySelector(".iti__country-list");
            counryList.style.width = "100%";
        });
    }
    pluginDestroy() {
        this.removeMask();
        this.removeCustomInputStyle();
        this.iti.destroy();
    }
}